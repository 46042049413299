import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import LeafBorder from "../images/hand-painted-leaf-border-cut.png"

const LeafBorderPicture = styled.div`
  background-image: url("${LeafBorder}");
  height: 30px;
  width: 270px;
  margin-bottom: 32px;
  background-size: 270px 30px;
`

const H1 = styled.h1`
  margin-bottom: 6px;
`

const P = styled.p`
  font-family: "Benton Sans";
  text-align: justify;
`

class SundayPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Sunday | Sara & Tim" />
        <H1>SUNDAY, OCTOBER 24TH</H1>
        <LeafBorderPicture />
        <P>
          For everyone staying the night in the area, we'll be having brunch in
          the property where we're staying. If you'd like to come along, let us
          know!
        </P>
      </Layout>
    )
  }
}

export default SundayPage
